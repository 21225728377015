// Footer.js
import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
	return (
		<div className="text-white  w-full pt-10   flex flex-col items-center bg-black">
			<div className="grid grid-cols-3 sm:gap-x-10 lg:gap-x-32 xl:gap-x-44">
				<a href="https://github.com/evanamedi" target="_blank" rel="noreferrer">
					<div className="flex flex-row mx-6 md:mx-16">
						<p className="mr-2 text-teal-400">|</p>
						<p className="hover:underline font-bold">Work</p>
					</div>
				</a>
				<Link className="" to="/about">
					<div className="flex flex-row mx-6 md:mx-16">
						<p className="mr-2 text-teal-400">|</p>
						<p className="hover:underline  font-bold ">About</p>
					</div>
				</Link>
				<a href="mailto:contact@evanamedi.com?subject=Development Work&body=Yes hello, I would like to work with you.">
					<div className="flex flex-row mx-6 md:mx-16">
						<p className="mr-2 text-teal-400">|</p>
						<p className="hover:underline  font-bold ">Contact</p>
					</div>
				</a>
			</div>
			<div className="grid grid-cols-3 sm:gap-x-10 lg:gap-x-32 xl:gap-x-44 text-sm mt-2">
				<a
					className="hover:underline "
					href="https://www.linkedin.com/in/evanamedi/"
					target="_blank"
					rel="noreferrer">
					<div className="flex flex-row mr-6 ml-12 md:mr-20 md:ml-10">
						<p className="mr-2 text-teal-400">|</p>
						<p className="text-gray-400">Linkedin</p>
					</div>
				</a>
				<a
					className="hover:underline "
					href="https://github.com/evanamedi"
					target="_blank"
					rel="noreferrer">
					<div className="flex flex-row ml-8 md:ml-14">
						<p className="mr-2 text-teal-400">|</p>
						<p className="text-gray-400">GitHub</p>
					</div>
				</a>
				<Link className="hover:underline " to="/demos">
					<div className="flex flex-row ml-4 diceMediumMargin">
						<p className="mr-2 text-teal-400">|</p>
						<p className="text-gray-400">Demos</p>
					</div>
				</Link>
			</div>
			<div className="mt-6 md:mt-6 mb-4 flex flex-row">
				{" "}
				<p className="text-xs text-white">© 2024 Evan Amedi. All rights reserved.</p>
			</div>
		</div>
	);
}
