import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import light from "../../static/images/light_mode.png";
import dark from "../../static/images/dark_mode.png";
import ea from "../../static/logo/ea.png";
import line from "../../static/images/icons/line.png";

function NavTest({ theme, toggleTheme }) {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		const handleWindowClick = (event) => {
			const menuButton = document.getElementById("menuButton");
			if (menuButton && !menuButton.contains(event.target)) {
				setIsOpen(false);
			}
		};

		window.addEventListener("click", handleWindowClick);

		return () => {
			window.removeEventListener("click", handleWindowClick);
		};
	}, []);

	return (
		<div className="pt-4 bg-black">
			<div
				className={`right-0 -mt-1 z-50 duration-500 ${isOpen ? "w-[100%]" : "w-full"} ${
					theme === "dark" ? "dark" : ""
				}`}>
				<div className="ml-4 top-2 absolute">
					<Link className="w-fit" to="/">
						<img src={ea} className="h-16 w-14" alt="the initials e and a" />
					</Link>
				</div>
				<button
					className="absolute top-5 left-24 dark:bg-opacity-0  z-50"
					onClick={toggleTheme}>
					<img
						id="light/dark"
						src={theme === "light" ? light : dark}
						alt="light/dark mode"
						className={theme === "light" ? "h-8 " : "h-8"}
					/>
				</button>
				<nav className="text-black font-semibold pb-2 mt-1 text-xl ">
					<div
						className={`flex justify-end mt-3 mb-6 mr-4 ${
							isOpen ? "items-center" : "pr-2"
						}`}>
						<button
							id="menuButton"
							className={`menu-icon ${isOpen ? "open" : ""}`}
							onClick={(e) => {
								e.stopPropagation();
								setIsOpen(!isOpen);
							}}>
							<img src={line} className="icon-bar top" alt="menu line" />
							<img src={line} className="icon-bar bottom" alt="menu line" />
						</button>
					</div>
					<div
						className={`font-semibold flex flex-col items-end transition-max-h duration-500 ease-in-out overflow-hidden bg-black  text-white rounded-tl-3xl rounded-tr-3xl ${
							isOpen ? "max-h-screen" : "max-h-0"
						} w-full z-50`}>
						<Link
							className="pb-6 hover:underline decoration-teal-400 mt-6 pr-6 text-7xl font-bebas"
							to="/">
							Home
						</Link>
						<Link
							className="pb-6 hover:underline decoration-teal-400 pr-6 text-7xl font-bebas"
							to="/about">
							About
						</Link>
						<a href="mailto:contact@evanamedi.com?subject=Development Work&body=Yes hello, I would like to work with you.">
							<div>
								<p className="pb-6 hover:underline decoration-teal-400 pr-6 text-7xl font-bebas">
									Contact
								</p>
							</div>
						</a>
					</div>
				</nav>
			</div>
		</div>
	);
}

export default NavTest;
