import React, { useEffect, useRef, useState } from "react";

export default function WorkContainer({ title, description, link, demoLink, picture, alt }) {
	const [isVisible, setIsVisible] = useState(false);
	const [transformStyle, setTransformStyle] = useState({
		transform: "rotateX(0deg) rotateY(0deg)",
	});
	const containerRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsVisible(true);
					observer.unobserve(containerRef.current);
				}
			},
			{
				threshold: 0.1,
			}
		);

		const currentRef = containerRef.current;
		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, []);

	const handleMouseEnter = (e) => {
		setTransformStyle({
			transform: "rotateX(2deg) rotateY(2deg)",
			transition: "transform 0.2s ease-out",
		});
	};

	const handleMouseMove = (e) => {
		const rect = e.target.getBoundingClientRect();
		const x = (e.clientX - rect.left) / rect.width - 0.5;
		const y = (e.clientY - rect.top) / rect.height - 0.5;

		let rotateX = y * 30;
		let rotateY = -x * 30;

		rotateX = Math.round(rotateX * 100) / 100;
		rotateY = Math.round(rotateY * 100) / 100;

		setTransformStyle({
			transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
			transition: "transform 0.1s ease-out",
		});
	};

	const handleMouseLeave = () => {
		setTransformStyle({
			transform: "rotateX(0deg) rotateY(0deg)",
			transition: "transform 1s ease-in-out",
		});
	};

	return (
		<div
			ref={containerRef}
			className={`work-container lg:flex ${isVisible ? "fade-in" : "fade-out"}`}>
			<div className="projectContainerText ">
				<div className="mb-6">
					<p className="mb-4 font-bold text-xl text-black dark:text-white">{title}</p>
					<p className="text-black dark:text-white">{description}</p>
				</div>
				<div className="mb-8 w-full">
					<a className="sourceCode " href={link} target="_blank" rel="noreferrer">
						Source Code
					</a>
					{demoLink && (
						<a
							className="sourceCode ml-4 "
							href={demoLink}
							target="_blank"
							rel="noreferrer">
							Live Demo
						</a>
					)}
				</div>
			</div>
			<div className="flex justify-center ">
				<img
					src={picture}
					alt={alt}
					className="projectPicture"
					style={transformStyle}
					onMouseEnter={handleMouseEnter}
					onMouseMove={handleMouseMove}
					onMouseLeave={handleMouseLeave}
				/>
			</div>
		</div>
	);
}
