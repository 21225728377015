import React, { useEffect, useState } from "react";
import LetsWork from "../subComponents/LetsWork";
import WorkDisplay from "../subComponents/WorkDisplay";
import Introduction from "../subComponents/Introduction";

export default function Home({ theme }) {
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		document.title = "Evan Amedi";

		const handleScroll = () => {
			const scrollHeight = document.documentElement.scrollHeight;
			const scrollTop = document.documentElement.scrollTop;
			const clientHeight = document.documentElement.clientHeight;

			if (scrollTop + clientHeight >= scrollHeight - 4000) {
				setShowButton(true);
			} else {
				setShowButton(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<div className="flex justify-center bg-black">
			<div className="rounding-t introBackground dark:bg-zinc-950 rounding-b ">
				<Introduction theme={theme} />
				<WorkDisplay />
				<LetsWork />
			</div>
			{showButton && (
				<button onClick={scrollToTop} className="fixed bottom-8 right-4 scrollTop">
					↑
				</button>
			)}
		</div>
	);
}
