import React, { useState } from "react";
import chevBlue from "../../static/images/icons/chevRightBlue.png";
import chevBlack from "../../static/images/icons/chevRightBlack.png";

export default function TimeLine({ company, title, brief, description, date, theme }) {
	const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

	const toggleDescription = () => {
		setIsDescriptionVisible(!isDescriptionVisible);
	};

	return (
		<div className="timelineContainer">
			<div className="flex timelineTextFade">
				<p className="mr-4 ">{company}</p>
				<p className="text-sm mt-1">{date}</p>
			</div>
			<div className="flex flex-row">
				<p className="mr-4 text-left text-nowrap font-extrabold">{title}</p>
				<button onClick={toggleDescription} className="-ml-2">
					<img
						src={theme === "dark" ? chevBlue : chevBlack}
						alt="down arrow"
						className={`h-6 w-10 arrow ${isDescriptionVisible ? "rotateArrow" : ""}`}
					/>
				</button>
			</div>
			<div></div>
			<div
				className={`timelineDescription  ${
					isDescriptionVisible ? "timelineDescriptionActive" : ""
				}`}>
				<p>{description}</p>
			</div>
		</div>
	);
}
