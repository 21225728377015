import React from "react";

export default function Personal() {
	return (
		<dib>
			<p>When asked, “What do you do?” my response usually comes down to this:</p>
			<p className="mt-16 mb-16">
				I'm an avid reader with an insatiable curiosity that drives me to explore a wide
				range of topics. Whether it's technology, science, chess, nutrition, political
				history, economics, or industry, I'm constantly seeking to expand my understanding.
				The more I learn, the more I realize how much there is still to discover—an insight
				that's both humbling and exhilarating.
			</p>
			<p className="mt-16 mb-16">
				When I'm not diving into books or new subjects, you'll likely find me outdoors or
				staying active in the gym. I'm passionate about cycling, running, hiking, and
				playing volleyball. I also enjoy lifting weights, and constantly striving to surpass
				my own expectations. Cooking is another passion of mine, particularly experimenting
				with the relationship between food and the body. And yes, I take great pride in my
				unofficial title as a professional watermelon consumer.
			</p>
			<p className="mt-16 mb-16">
				{`I also have a knack for fixing and building things. With a background in
				construction and automotive work, I've spent significant time building and repairing
				homes, furniture, tools, and cars. In recent years, my focus has shifted to creating
				software tools and applications, primarily within the Linux and macOS ecosystems.
				What fascinates me about software development is the boundless potential it
				offers—unlike physical objects, the only limit is the mind (with a few hardware
				constraints, of course)`}
			</p>
			<p className="mt-16 mb-16">
				At heart, I'm a social person who loves meeting new people and learning about their
				lives. I believe the company we keep shapes who we are and the life we lead, so I
				particularly enjoy spending time with those who challenge me in various aspects of
				life.
			</p>
			<p className="mt-16 mb-16">
				People often describe me as laid-back and nonchalant, and I'm pretty comfortable
				with that description. However, I also pride myself on being decisive and
				action-oriented. There's so much to experience in life, and never enough time to do
				it all.
			</p>
		</dib>
	);
}
