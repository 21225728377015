import React from "react";
import notFoundPic from "../../static/images/notfound.jpg";

export default function NotFound() {
	return (
		<div className="flex justify-center bg-black h-screen">
			<div className="text-white mt-36 mb-36 px-6 ">
				<div className="flex flex-row justify-center mx-14 sm:mx-0 -mt-16 mb-6">
					<div className="mt-6 font-bebas w-full">
						<p className="text-6xl text-center  text-teal-400">Page Not Found</p>
					</div>
					<img
						src={notFoundPic}
						className="w-64"
						alt="friendly robot holding a tool box and wrench- with 404 not found displayed on its chest"
					/>
				</div>
				<div className="text-left max-w-lg">
					<p>
						Sorry, not sure what you're looking for, but our operators were{" "}
						<span className="text-amber-500">not able to find it.</span>
					</p>
					<p className="mt-4">
						The link may have been{" "}
						<span className="text-amber-500">typed incorrectly</span> or
						<span className="text-amber-500"> no longer exists.</span>
					</p>
				</div>
			</div>
		</div>
	);
}
