import React, { useState } from "react";
import Professional from "./Professional";
import Personal from "./Personal";

export default function AboutMenu({ theme }) {
	const [selectedComponent, setSelectedComponenet] = useState("professional");

	return (
		<div className="w-full flex justify-center flex-col">
			<div className="w-full flex justify-center -ml-1">
				<p className="text-5xl mt-8 font-bebas">{`// About`}</p>
			</div>
			<div className="flex justify-center mt-2 font-mono">
				<button
					onClick={() => setSelectedComponenet("professional")}
					className={`aboutButton ${
						selectedComponent === "professional" ? "aboutIsActive" : ""
					}`}>
					Professional
				</button>
				<button
					onClick={() => setSelectedComponenet("personal")}
					className={`aboutButton ${
						selectedComponent === "personal" ? "aboutIsActive" : ""
					}`}>
					Personal
				</button>
			</div>
			<div className="w-full flex justify-center px-10">
				<div className="flex justify-center mt-16 max-w-4xl">
					{selectedComponent === "professional" && <Professional theme={theme} />}
					{selectedComponent === "personal" && <Personal />}
				</div>
			</div>
		</div>
	);
}
