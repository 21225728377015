import React from "react";

export default function TileLogo({ picture, alt, color, link }) {
	return (
		<div className="relative">
			<a href={link} target="_blank" rel="noreferrer">
				<div
					className={`${color}  h-20 w-12 rounded-md flex justify-center items-center mx-2 border border-white border-opacity-30 my-4 relative z-50 shadow-md shadow-slate-500 transition-shadow duration-300  hover:shadow-xl`}>
					<img src={picture} alt={alt} className="h-12 w-12 p-1 " />
				</div>
			</a>
		</div>
	);
}
