import React from "react";
import TileLogo from "../subComponents/TileLogo";
import python from "../../static/logo/python-logo-only.png";

import javascript from "../../static/logo/JavaScript-logo.png";
import C from "../../static/logo/C_Logo.png";
import bash from "../../static/logo/bash_logo.png";
import html from "../../static/logo/html.png";
import css from "../../static/logo/CSS3_logo.png";
import node from "../../static/logo/node.png";
import express from "../../static/logo/express.png";
import react from "../../static/logo/react.png";
import tailwind from "../../static/logo/tailwind.png";
import rust from "../../static/logo/rustLogo.png";

export default function TileLogoFull() {
	return (
		<div>
			<div className="flex justify-center w-full ">
				<div className=" w-9/12 relative">
					<div className="flex-wrap flex flex-row my-1">
						<div>
							<TileLogo
								picture={rust}
								alt="rust logo"
								color="bg-gradient-to-r from-gray-100 to-gray-300 hover:shadow-slate-500"
								link="https://www.rust-lang.org"
							/>
						</div>
						<div>
							<TileLogo
								picture={python}
								alt="python logo"
								color="bg-gradient-to-r from-blue-900 to-blue-950 hover:shadow-slate-500"
								link="https://www.python.org"
							/>
						</div>
						<div>
							<TileLogo
								picture={javascript}
								alt="javascript logo"
								color="bg-gradient-to-l from-yellow-600 to-yellow-500 hover:shadow-slate-500"
								link="https://www.javascript.com"
							/>
						</div>
						<div>
							<TileLogo
								picture={C}
								alt="C logo"
								color="bg-gradient-to-r from-slate-500 to-slate-300 hover:shadow-slate-500"
								link="https://www.gnu.org/software/gnu-c-manual/"
							/>
						</div>
						<div>
							<TileLogo
								picture={bash}
								alt="bash logo"
								color="bg-gradient-to-l from-gray-700 to-gray-900 hover:shadow-slate-500"
								link="https://www.gnu.org/software/bash/"
							/>
						</div>
						<div>
							<TileLogo
								picture={html}
								alt="html logo"
								color="bg-gradient-to-r from-yellow-900 to-yellow-950 hover:shadow-slate-500"
								link="https://developer.mozilla.org/en-US/docs/Web/HTML"
							/>
						</div>
						<div>
							<TileLogo
								picture={css}
								alt="css logo"
								color="bg-gradient-to-r from-pink-800 to-pink-900 hover:shadow-slate-500"
								link="https://developer.mozilla.org/en-US/docs/Web/CSS"
							/>
						</div>
						<div>
							<TileLogo
								picture={node}
								alt="node logo"
								color="bg-gradient-to-r from-green-950 to-green-900 hover:shadow-slate-500"
								link="https://nodejs.org/en"
							/>
						</div>
						<div>
							<TileLogo
								picture={react}
								alt="react logo"
								color="bg-gradient-to-r from-react1 to-react2 hover:shadow-slate-500"
								link="https://react.dev"
							/>
						</div>
						<div>
							<TileLogo
								picture={express}
								alt="express logo"
								color="bg-gradient-to-l from-black to-darkkgrey hover:shadow-slate-500"
								link="https://expressjs.com/"
							/>
						</div>

						<div>
							<TileLogo
								picture={tailwind}
								alt="tailwind logo"
								color="bg-gradient-to-r from-teal-950 to-teal-900 hover:shadow-slate-500"
								link="https://tailwindcss.com"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
