import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavTest from "./components/subComponents/mainNav";
import Home from "./components/mainComponents/Home";
import About from "./components/mainComponents/About";
import Footer from "./components/mainComponents/Footer";
import ScrollToTop from "./components/subComponents/ScrollToTop";
import NotFound from "./components/mainComponents/NotFound";

function App() {
	const [theme, setTheme] = useState("light");

	const toggleTheme = () => {
		const newTheme = theme === "light" ? "dark" : "light";
		setTheme(newTheme);
		try {
			localStorage.setItem("theme", newTheme);
		} catch (error) {
			console.error("Could not store theme in localStorage", error);
		}
		document.documentElement.classList.toggle("dark", newTheme === "dark");
	};

	useEffect(() => {
		try {
			const savedTheme = localStorage.getItem("theme");
			if (savedTheme) {
				setTheme(savedTheme);
				document.documentElement.classList.toggle(
					"dark",
					savedTheme === "dark"
				);
			}
		} catch (error) {
			console.error("Could not retrieve theme from localStorage", error);
		}
	}, []);

	return (
		<Router>
			<ScrollToTop />
			<div className="rounding-t bg-white rounding-b">
				<div className="relative">
					<header>
						<NavTest theme={theme} toggleTheme={toggleTheme} />
					</header>
					<div>
						<Routes>
							<Route
								path="/"
								element={
									<Home
										theme={theme}
										toggleTheme={toggleTheme}
									/>
								}
							/>
							<Route
								path="/about"
								element={
									<About
										theme={theme}
										toggleTheme={toggleTheme}
									/>
								}
							/>
							<Route path="*" element={<NotFound />} />
						</Routes>
					</div>
					<footer>
						<Footer />
					</footer>
				</div>
			</div>
		</Router>
	);
}

export default App;
