import React from "react";
import TimeLine from "./TimeLine";

export default function Professional({ theme }) {
	return (
		<div>
			<p>
				As a pragmatic and thoughtful leader, I have spent a decade in business and sales
				providing consistent results and selfless contributions to my teams and clients.
				While I pride myself on being an independent and critical thinker, I place a high
				value on the results that can be achieved through efficient team work and high
				levels of communication.
			</p>
			<p className="mt-16">
				My effectiveness as a Software Developer stems not just from my familiarity with
				various tech stacks, but from my ability to see the big picture and understand how
				each part contributes to the whole. This, combined with my strong communication
				skills and adaptability, has enabled me to thrive in diverse roles and industries.
			</p>
			<p className="mt-16">
				Currently, I focus on low-level development, primarily in Rust and C. Previously, I
				have worked on client-side & backend development with frameworks like Flask, React,
				Express, and other tools within the Node ecosystem- as well as occasional
				development using C. I also enjoy creating command-line tools that enhance
				development speed while ensuring consistency and maintainability.
			</p>
			<p className="mt-16">
				Looking ahead, I am committed to contributing to the development of tools and
				infrastructure critical for maintaining user security and system integrity. While
				user behavior is beyond our control, we can ensure that the applications they use,
				and the underlying infrastructure, are reliable and secure.
			</p>
			<div className="mt-16 flex justify-center mb-16 ">
				<TimeLine
					company="Freelancer"
					title="Software Developer"
					date="02/2023 - Current"
					brief="It was popularised in the 1960s with
				the release."
					description="As a freelance software developer, I have been fortunate enough to work with clients on incredibly engaging and interesting projects ranging from custom CRM solutions in the automotive industry, to incorporating necessary automation to free my clients from uninteresting repetitive work so they can focus on engaging in more impactful work. My extensive history in business provides me with the ability to effectively translate clients needs into technical requirements and provide solutions that truly make an impact."
					theme={theme}
				/>
			</div>
			<div className="mt-16 flex justify-center mb-16 ">
				<TimeLine
					company="TNUC"
					title="General Manager"
					date="02/2022 - 04/2023"
					brief="It was popularised in the 1960s with
				the release."
					description="As GM of TN Used Cars I was able to optimize our operations and streamline inventory procurement to cut unnecessary costs and provide faster turnover. In addition to the realized savings from higher operational efficiency, and newly implemented CRM and sales technology solutions, we were able to achieve higher sales growth and customer satisfaction,"
					theme={theme}
				/>
			</div>
			<div className="mt-16 flex justify-center mb-16 ">
				<TimeLine
					company="Bar/Nightlife Industry"
					title="Consultant"
					date="12/2020 - 01/2022"
					brief="It was popularised in the 1960s with
				the release."
					description="A rewarding opportunity that allowed me to connect with venues both struggling and successful to grow their bar programs and deliver better experiences. My main area of focus included: uncovering and addressing bottle necks, maximizing margins through a combination of better product offerings, minimizing product waste, and a better understanding the unique needs of their customers, as well as implementing better communication & maximizing DPM (drinks per minute)."
					theme={theme}
				/>
			</div>
			<div className="mt-16 flex justify-center mb-16 ">
				<TimeLine
					company="Hideout"
					title="Owner / GM"
					date="12/2018 - 01/2021"
					brief="It was popularised in the 1960s with
				the release."
					description="As owner and GM of Hideout Bar/Lounge I oversaw daily operations, finances, and parts of marketing strategy. Upon assuming the venue from the previous owner, I sought to remove unnecessary costs and product offerings to reduce waste and increase margins. In collaboration with our team-lead and production manager, we were able to pinpoint our target demographic to implement better marketing campaigns, and more relevant product offerings. This allowed us to maintain a leaner operation and stimulate growth. Additionally, through collaboration with various artists, performance groups, and organizations, we were able to foster a welcoming & energetic environment embedded with the local community."
					theme={theme}
				/>
			</div>
			<div className="mt-16 flex justify-center mb-16 ">
				<TimeLine
					company="Sama Construction"
					title="Owner / Operations Manager"
					date="06/2013 - 05/2018"
					brief="It was popularised in the 1960s with
				the release."
					description="As the Operations Manager of Sama Construction, a home improvement and remodeling business, I oversaw projects ranging from Atlanta, GA to Nashville, TN dealing in mostly residential & occasional commercial projects. In addition to my titled role, I spent time blending into other roles such as sales and customer relations to help maintain a new and growing business."
					theme={theme}
				/>
			</div>
		</div>
	);
}
