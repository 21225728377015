import React, { useEffect } from "react";
import AboutMenu from "../subComponents/AboutMenu";

function About({ theme }) {
	useEffect(() => {
		document.title = "About";
	}, []);

	return (
		<div className="bg-black w-full">
			<div className="rounding-t bg-slate-300 dark:bg-black border border-teal-400 rounding-b border-opacity-0 dark:border-opacity-100  my-div text-black dark:text-white  ">
				<div className="flex justify-center">
					<AboutMenu theme={theme} />
				</div>
			</div>
		</div>
	);
}

export default About;
