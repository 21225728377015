import React from "react";
import { Link } from "react-router-dom";
import TileLogoFull from "./TileLogoFull";

export default function LetsWork() {
	return (
		<div className="rounding-b  py-10 introBackground border-b border-l border-r border-slate-300 dark:bg-zinc-950 dark:border-teal-400    dark:border-opacity-100">
			<div className="flex justify-center ">
				<div className="flex flex-col justify-center w-9/12 ml-2">
					<p className="text-5xl work text-slate-200 dark:text-white font-bebas mt-28 letsWorkLetsWork">
						Let's Work{" "}
						<span className="dark:text-teal-400 text-black letsWorkTogether">
							Together
						</span>
					</p>
					<p className="mt-10 text-xl dark:text-gray-300 h-20">
						<span className="font-bold">
							<Link className="learnMore" to="/about">
								{`Learn more`}
							</Link>
						</span>
						{` about me`}
					</p>
				</div>
			</div>
			<div className="pb-16">
				<TileLogoFull />
			</div>
		</div>
	);
}
